import { createStore } from "vuex";
// import { app } from "../main"; //匯入我們的Vue Instance

export default createStore({
  state: {
    lang: "cn",
    // login: "",
    user: null,
    order_id: "",
    order_status: 1,
    station_id: "",
    station_search_id: "",
    logo: null,
    payment: null,
    payment_content: [],
    report_type: 1,
    purchase_order: null,
    out_stock_total: [],
    out_stock: [],
    out_stock_type: "",
    out_stock_transfer: null,
    out_stock_audit: null,
  },
  mutations: {
    set_out_stock_total(state, out_stock_total) {
      state.out_stock_total = out_stock_total;
    },
    set_out_stock_audit(state, out_stock_audit) {
      state.out_stock_audit = out_stock_audit;
    },
    set_out_stock_transfer(state, out_stock_transfer) {
      state.out_stock_transfer = out_stock_transfer;
    },
    set_out_stock_type(state, out_stock_type) {
      state.out_stock_type = out_stock_type;
    },
    set_out_stock(state, out_stock) {
      state.out_stock = out_stock;
    },
    set_purchase_order(state, purchase_order) {
      state.purchase_order = purchase_order;
    },
    set_report_type(state, report_type) {
      state.report_type = report_type;
    },
    set_payment(state, payment) {
      state.payment = payment;
    },
    set_payment_content(state, payment_content) {
      state.payment_content = payment_content;
    },
    set_logo(state, logo) {
      state.logo = logo;
    },
    set_lang(state, value) {
      state.lang = value;
    },
    set_order_id(state, value) {
      state.order_id = value;
    },
    set_station_id(state, value) {
      state.station_id = value;
    },
    set_station_search_id(state, value) {
      state.station_search_id = value;
    },
    set_order_status(state, value) {
      state.order_status = value;
    },
    // set_login(state, login) {
    //   state.login = login;
    // },
    set_user(state, user) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
